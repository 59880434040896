function createAndMountIframe() {
  const iframe = document.createElement('iframe');
  iframe.src = `${dataUrl()}widget/nodificx?secret=${getSecretAttribute()}&cxhost=${location.hostname}`;
  iframe.style.height = '100%';
  iframe.style.width = '100%';
  iframe.style.border = 'none';
  iframe.style.minHeight = '800px';

  const nodifiWidget = document.getElementById('nodifi-widget');

  if (!nodifiWidget) {
    throw Error('No mounting element found');
  }

  addNodifiWidgetStyles(nodifiWidget).appendChild(iframe);
}

function addNodifiWidgetStyles(element: HTMLElement) {
  element.removeAttribute('style');
  element.style.display = 'flex';
  element.style.position = 'relative';
  element.style.flexDirection = 'column';
  element.style.height = '100%';
  element.style.minHeight = '800px';
  return element;
}

function widgetName(): string {
  return import.meta.env.VITE_WIDGET_NAME;
}

function dataUrl(): string {
  return import.meta.env.VITE_WIDGET_DATA_URL;
}

function getScriptElement() {
  const el = document.querySelector(`script[data-name="${widgetName()}"]`);

  if (!el) {
    throw Error(`Could not find script tag for ${widgetName()}`);
  }

  return el;
}

function getSecretAttribute() {
  const attribute = getScriptElement().getAttribute('data-secret');

  if (!attribute) {
    throw Error(`Could not find secret attribute`);
  }

  return attribute;
}

createAndMountIframe();
